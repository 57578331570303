export enum FilterOperator {
    equal = "=",
    notEqual = "!=",
    greaterThan = ">",
    lessThan = "<",
    greaterThanEqualTo = ">=",
    lessThanEqualTo = "<=",
}

export enum SortDirection {
    asc = "ASC",
    desc = "DESC",
}

export enum CustomBreakpointNames {
    xl = "(min-width: 1200px)",
    lg = "(min-width: 992px)",
    md = "(max-width: 991.98px)",
    xs = "(max-width: 575.98px)",
}
  